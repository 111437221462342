import http from '../http';

export function getType (params) {
    return http({
        url: "/api/ltd/projectTypePage",
        method: "get",
        params:params
    })
}

export function addType (data) {
    return http({
        url: "/api/ltd/projectType",
        method: "put",
        data:data
    })
}
export function deleteType (data) {
    return http({
        url: "/api/ltd/projectType",
        method: "delete",
        data:data
    })
}
export function editType (data) {
    return http({
        url: "/api/ltd/projectType",
        method: "post",
        data:data
    })
}
