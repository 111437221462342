<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"
        @addType="addType"

    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @editType="editType"
        @deleteType="deleteType"

    />
    <!--addDialog 组件 -->
    <common-add-dialog
        :addDialogData="addProjectTypeData"
        @addTypeSub="addTypeSub"
    />

  </div>
</template>
<script>
// import {timer} from '@/assets/js/comment'
import {getType, addType, deleteType, editType} from '@/api/basic_management/project_type'

export default {
  components: {},
  props: [],
  data() {
    return {
      //title 数据源
      titleProp: {
        name: '项目类型设置',//标题名称
        butOperate: [{
          name: '添加项目类型',
          type: 'primary',
          method: 'addType',
          id: 2000,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '编码',
          field: 'p_type',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目类型',
          field: 'type_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '编辑',
            type: 'primary',
            method: 'editType',
            status: '20000',
            id: 20000,
          }, {
            name: '删除',
            type: 'danger',
            method: 'deleteType',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      //addDialog数据源
      addProjectTypeData: {
        isDialog: false,//dialog是否显示
        subMethod: 'addTypeSub',//点击确定方法名
        title: '添加项目类型',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 数据源
        addDialogProp: [{
          label: '项目类型名称',
          field: 'type_name',
          type: 2,
          show: true,
        }],
        //dialog 显示数据
        addDialogShowData: {
          type_name: '',
        },
        // 下拉框的 options和单选框，多选框
      },
      //表格数据
      tableData: [],
      //addDialog提交数据
      editId:'',

    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        // keywords: this.tabsProp.keywords,
        // this.tabsProp.active //tabs 当前的值
        // name: this.tabsProp.heighKewWordField.name
      }
      getType(params).then((result) => {
        console.log(result)
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        this.tableData = res.data
      })
    },
    //title点击 添加类型 按钮
    addType() {
      console.log('添加类型')
      this.addProjectTypeData.title = '添加项目类型'
      this.editId = ''
      this.addProjectTypeData.addDialogShowData.type_name = ''
      this.addProjectTypeData.isDialog = true;
    },

    //table 编辑 按钮点击
    editType(row, index) {
      console.log(row)
      console.log(index)
      console.log('编辑 点击')
      this.editId = row.id
      this.addProjectTypeData.title = '编辑项目类型'
      this.addProjectTypeData.addDialogShowData.type_name = row.type_name
      this.addProjectTypeData.isDialog = true
    },
    //table 作废 按钮点击
    deleteType(row, index) {
      console.log(row)
      console.log(index)
      console.log('作废 点击')
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteType({id: row.id}).then((result) => {
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.tableData.splice(index, 1)
        })
      })

    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
    // addDialog 点击确定
    addTypeSub() {
      let subData = {...this.addProjectTypeData.addDialogShowData}  //ES6深拷贝
      if (this.editId){
        console.log('编辑')
        subData.id = this.editId
        editType(subData).then((result) => {
          this.addProjectTypeData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
      } else {
        console.log('添加')
        addType(subData).then((result) => {
          this.addProjectTypeData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
